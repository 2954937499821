<template>
    <tr>
        <td>{{StringsHelper.getDay(day.day)}}</td>
        <td>{{day.open_hour}}</td>
        <td>{{day.close_hour}}</td>
        <td><span v-if="day.is_open">Aperto</span>
            <span v-else>Chiuso</span></td>
    </tr>
</template>

<script>

import baseMixin from '@/common/baseMixin'

export default {
    name: "SiteTable",
    mixins: [baseMixin],
    props: {
        day: {
            type: Object,
            required: true
        }
    }
}
</script>
