<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row">
                <div class="row-xs-2">
                    <h1 class="h2">Orari dei Siti</h1>
                </div>
            </div>
                <SiteTable :sites="sites"/>
        </div>
    </ContainerWithMenu>
</template>

<script>
import ContainerWithMenu from "../components/container/ContainerWithMenu";
import SiteTable from "../components/Site/SiteTables"
import {getSites} from "../api"
import baseMixin from "../common/baseMixin"

export default {
    title: "Orari dei Siti",
    name: "sites",
    components: {ContainerWithMenu,SiteTable},
    mixins: [baseMixin],
    data: function() {
        return {
            loading: false,
            sites: [],
        }
    },
    mounted() {
        this.getSites();
    },
    methods: {
        async getSites() {
            this.loading = true;
            try {
                let sitesResponse = await getSites();
                this.sites = sitesResponse.results;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                console.log(e)
            }
        }
    }
};
</script>
