<template>
    <table class="table table-hover">
        <tbody id="reservation-list">
        <tr>
            <th>Giorno</th>
            <th>Orario di Apertura</th>
            <th>Orario di Chiusura</th>
            <th>Stato</th>
        </tr>
        <SiteRow v-for="day in opening_hours" :day="day" v-bind:key="day.id" />
        </tbody>
    </table>
</template>

<script>

import baseMixin from '@/common/baseMixin'
import SiteRow from './SiteRow'

export default {
    name: "SiteTable",
    mixins: [baseMixin],
    components: {SiteRow},
    data: () => {
        return {
            opening_hours: null,
        }
    },
    props: {
        site: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.opening_hours = this.site.opening_hours
    }
}
</script>
