<template>
    <div>
        <div v-for='site in sites' v-bind:key ='site.id'>
                <div class="margin-top-40">
                    <h3 class="title-site margin-bottom-20 margin-right-20">{{site.name}}</h3>
                    <router-link class="btn btn-primary" :to="{name: 'site_open_hours', params: {site_id: site.id}}">
                        <span aria-hidden="true"></span>
                        Modifica Orari
                    </router-link>
                </div>
            <SiteTable :site="site"/>
        </div>
    </div>
</template>
<script>

import SiteTable from "./SiteTable";
export default {
    name: "SiteTables",
    components:{SiteTable},
    props: {
        sites: {
            type: Array,
            required: true
        }
    }
}
</script>
